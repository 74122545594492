/*
 *
 * UserPreferences reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import { resetTeamFilterAct, updateTeamFilterAct } from './actions';
import { TEAM_STATUS_OPTIONS } from '../../utils/options.util';

export const initialState = {
  teamFilters: {
    search: '',
    ts: [TEAM_STATUS_OPTIONS[0]],
    isIncompleteTeam: '',
    hasNoSupporter: '',
    isMedicalCertificateCompleted: '',
    selectedDonationRanges: [],
    selectedUsers: [],
    selectedCompanyNames: [],
    selectedTag: null,
    startTimeValue: null,
    selectedWEWCStatuses: [],
    hasStartTime: '',
    hasBookedMeal: '',
    ob: '',
    eventIds: [],
    teamTypes: [],
    gender: '',
    memberCount: '',
    registrationMonth: '',
    selectedRaisedPercentages: [],
    sortOrder: {
      name: 'registrationDate',
      direction: 'desc',
    },
    teamGroup: null,
  },
};

/* eslint-disable default-case, no-param-reassign */
const userPreferencesSlice = createSlice({
  name: 'UserPreferences',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateTeamFilterAct, (state, { payload }) => {
        const { filters, reset } = payload;
        if (reset) {
          state.teamFilters = { ...initialState.teamFilters, ts: [] };
        } else {
          state.teamFilters = { ...state.teamFilters, ...filters };
        }
      })
      .addCase(resetTeamFilterAct, state => {
        state.teamFilters = { ...initialState.teamFilters };
      });
  },
});

export default userPreferencesSlice.reducer;

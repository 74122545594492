/*
 *
 * UserPreferences actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import { RESET_TEAM_FILTER, UPDATE_TEAM_FILTER } from './constants';

export const updateTeamFilterAct = createAction(
  UPDATE_TEAM_FILTER,
  (filters, reset = false) => ({
    payload: {
      filters,
      reset,
    },
  }),
);

export const resetTeamFilterAct = createAction(RESET_TEAM_FILTER);

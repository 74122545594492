import BaseApiInstance from './base';
const BASE_URL = '/events';
export const getEventListApi = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}`, {
    params,
  });
  return response;
};

export const getEventApi = async eventId => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${eventId}`);
  return response;
};

export const getEventDetailApi = async ({ eventId, params }) => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${eventId}/detail`, {
    params,
  });
  return response;
};

export const getEventTeamsApi = async (eventId, params) => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${eventId}/teams`, {
    params,
  });
  return response;
};

export const getEventLinkFormsApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/link-form`,
  );
  return response;
};

export const updateLinkFormApi = async ({ eventId, data }) => {
  const response = await BaseApiInstance.post(
    `${BASE_URL}/${eventId}/link-form`,
    data,
  );
  return response;
};

export const getEventVolunteersApi = async (eventId, params) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/volunteers`,
    {
      params,
    },
  );
  return response;
};
export const getEventMembersApi = async (eventId, params) => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${eventId}/members`, {
    params,
  });
  return response;
};

export const getVolunteerUniqueZipCodesByEventApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/volunteers/zip-codes`,
  );
  return response;
};

export const getMemberUniqueZipCodesApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/members/zip-codes`,
  );
  return response;
};

export const getMemberUniqueSourcesApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/members/sources`,
  );
  return response;
};

export const getMemberUniqueCountriesApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/members/countries`,
  );
  return response;
};

export const getTeamUniqueCompaniesApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/team/companies`,
  );
  return response;
};

export const getEventSupportersApi = async ({ eventId, params }) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/supporters`,
    {
      params,
    },
  );
  return response;
};

export const getSupporterUniqueZipCodesApi = async ({ eventId }) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/supporters/zip-codes`,
  );
  return response;
};

export const getSupporterUniqueCountriesApi = async ({ eventId }) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/supporters/countries`,
  );
  return response;
};

export const getUnlinkedEvents = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}/without-group`, {
    params,
  });
  return response;
};

export const getUsersAssignedToTeamByEventApi = async eventId => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${eventId}/resp-users`,
  );
  return response;
};
